<template>
  <alumnoForm
    btnSubmit="Crear Alumno"
    :alumno="data"
    @processForm="agregar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import alumnoForm from './components/AlumnosForm.vue'

export default {
  components: {
    alumnoForm,
  },
  data() {
    return {
      data: {
        numMatricula: '',
        tipoDocumento: 'RUT',
        rut: '',
        nombres: '',
        primerApellido: '',
        segundoApellido: '',
        correo: '',
        genero: 'Femenino',
        fechaNacimiento: null,
        paci: null,
        pie: null,
        estado: null,
        idDiagnostico: '',
        idPrioritario: '',
        idCurso: '',
        idEstablecimiento: '',
      },
    }
  },
  mounted() {
    // si el usuario no es admin, mostrar el colegio activo
  },
  methods: {
    ...mapActions({ createAlumno: 'alumnos/addAlumnos' }),
    async agregar(alumno) {
      const response = await this.createAlumno(alumno)
      const errorAlumnos = store.state.alumnos
      const errorMessage = errorAlumnos.errorMessage.errors
      if (response.status === "success") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Alumno creado 👍',
            icon: 'CheckIcon',
            text: `El alumno "${alumno.nombre}" fue creado con éxito!`,
            variant: 'success',
          },
        },
        {
          position: 'bottom-right',
          timeout: 4000,
        })
        this.$router.replace({
          name: 'alumnos',
        })
      } else if (response.status === 'error') {
        this.$swal({
          title: 'Error!',
          text: `${response.message}`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {

        this.$swal({
          title: 'Error!',
          text: 'Ingreso de datos fraudulento!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }

    },
  },
}
</script>
