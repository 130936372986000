// We haven't added icon's computed property because it makes this mixin coupled with UI
export const rut = {
  data() {
    return {
      // passwordFieldType: 'password',
    }
  },
  methods: {
    formatRut(rutVal) {
      // XX.XXX.XXX-X
      let rutFormateado
      const newRut = rutVal.replace(/\./g, '').replace(/-/g, '').trim().toLowerCase()
      if (newRut.length <= 9) {
        // newRut = newRut.substr(0, newRut.length - 1)
        let lastDigit = newRut.substr(-1, 1)
        const rutDigit = newRut.substr(0, newRut.length - 1)

        let format = ''
        /* eslint-disable no-plusplus */
        for (let i = rutDigit.length; i > 0; i--) {
          const e = rutDigit.charAt(i - 1)
          format = e.concat(format)
          if (i % 3 === 0) {
            format = '.'.concat(format)
          }
        }
        const soloNumeros = /^[0-9]+$/
        const soloK = /^[k]+$/

        if (!lastDigit.match(soloNumeros)) {
          if (!lastDigit.match(soloK)) {
            lastDigit = lastDigit.replace(lastDigit, '')
          }
        }
        rutFormateado = format.concat('-').concat(lastDigit)
      } else if (newRut.length === 10) {
        // newRut = newRut.substr(0, newRut.length - 1)
        let lastDigit = newRut.substr(-1, 1)
        const rutDigit = newRut.substr(0, newRut.length - 1)

        let format = ''
        let rutnumbers = ''
        /* eslint-disable no-plusplus */
        for (let i = rutDigit.length; i > 0; i--) {
          const e = rutDigit.charAt(i - 1)
          rutnumbers = `${e}${rutnumbers}`
          if (i === 7) {
            format = '.'.concat(rutnumbers)
            rutnumbers = ''
          }
          if (i === 4) {
            format = `${rutnumbers}${format}`
            rutnumbers = ''
          }
          if (i === 1) {
            format = `${rutnumbers}${'.'.concat(format)}`
            rutnumbers = ''
          }
        }
        const soloNumeros = /^[0-9]+$/
        const soloK = /^[k]+$/

        if (!lastDigit.match(soloNumeros)) {
          if (!lastDigit.match(soloK)) {
            lastDigit = lastDigit.replace(lastDigit, '')
          }
        }
        rutFormateado = format.concat('-').concat(lastDigit)
      }

      return rutFormateado === '-' ? '' : rutFormateado
    },
  },
  formatRutSinPuntoGuion(rutVal) {
    return rutVal.replace('-', '').replace('.', '').replace('.', '')
  },
}

export const _ = null
