<template>
  <b-overlay
    :show="!cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-card
      aria-hidden="true"
      class="mb-0"
    >
      <!-- Header: Personal Info -->
      <div class="d-flex mb-1 mt-0">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Información Personal
        </h4>
      </div>

      <b-row>

        <!-- Field: TipoDocumento -->
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="Tipo Documento *"
            label-for="tipoDocumento"
            :state="$v.alumno.tipoDocumento.$invalid
              && $v.alumno.tipoDocumento.$dirty
              ? false
              : null"
          >
            <!-- v-model="alumno.tipoDocumento" -->
            <v-select
              v-model="alumno.tipoDocumento"
              placeholder="Selecciona el tipo documento"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tipoDocumentoOptions"
              label="nombre"
              :reduce="option => option.id"
            />
          </b-form-group>
          <b-form-invalid-feedback
            v-if="$v.alumno.tipoDocumento.$invalid
              && $v.alumno.tipoDocumento.$dirty"
            id="idTipoDocumentoInfo"
          >
            El campo tipo documento
            <span v-if="!$v.alumno.tipoDocumento.required">
              es requerido.
            </span>
          </b-form-invalid-feedback>
        </b-col>

        <!-- Field: rut -->
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="Rut *"
            label-for="rut"
          >
            <b-form-input
              id="rut"
              v-model="alumno.rut"
              placeholder="Ingresa el rut"
              :state="$v.alumno.rut.$invalid && $v.alumno.rut.$dirty
                ? false
                : null"
              @keyup="alumno.rut = formatRut(alumno.rut)"
            />
            <b-form-invalid-feedback
              v-if="$v.alumno.rut.$invalid && $v.alumno.rut.$dirty"
              id="rutInfo"
            >
              El campo rut
              <span v-if="!$v.alumno.rut.required">
                es requerido.
              </span>
              <span v-if="!$v.alumno.rut.maxLength">
                debe tener máximo 15 caracteres.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Fecha Nacimiento -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Fecha Nacimiento *"
            label-for="fechaNacimiento"
          >
            <b-form-datepicker
              id="fecha"
              v-model="alumno.fechaNacimiento"
              locale="es"
              :state="$v.alumno.fechaNacimiento.$invalid
                && $v.alumno.fechaNacimiento.$dirty
                ? false
                : null"
              placeholder="Selecciona la fecha nacimiento"
              start-weekday="1"
              :hide-header="true"
              :show-decade-nav="true"
            />
            <b-form-invalid-feedback
              v-if="$v.alumno.fechaNacimiento.$invalid
                && $v.alumno.fechaNacimiento.$dirty"
              id="fechaNacimientoInfo"
            >
              El campo fecha nacimiento
              <span v-if="!$v.alumno.fechaNacimiento.required"> es requerido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Genero -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Genero"
            label-for="genero"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="genero"
              v-model="alumno.genero"
              value="Femenino"
              :options="generoOptions"
              :state="$v.alumno.fechaNacimiento.$invalid
                && $v.alumno.fechaNacimiento.$dirty
                ? false
                : null"
            />
          </b-form-group>
          <b-form-invalid-feedback
            v-if="$v.alumno.genero.$invalid && $v.alumno.genero.$dirty"
            id="generoInfo"
          >
            El campo genero
            <span v-if="!$v.alumno.genero.required">
              es requerido.
            </span>
          </b-form-invalid-feedback>
        </b-col>

        <!-- Field: Nombres -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nombres *"
            label-for="nombres"
          >
            <b-form-input
              id="nombres"
              v-model="alumno.nombres"
              placeholder="Ingresa el o los nombres"
              :state="$v.alumno.nombres.$invalid && $v.alumno.nombres.$dirty
                ? false
                : null"
            />
            <b-form-invalid-feedback
              v-if="$v.alumno.nombres.$invalid && $v.alumno.nombres.$dirty"
              id="nombresInfo"
            >
              El campo nombres
              <span v-if="!$v.alumno.nombres.required">
                es requerido.
              </span>
              <span v-if="!$v.alumno.nombres.maxLength">
                debe tener máximo 250 caracteres.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: PrimerApellido -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Apellido Paterno *"
            label-for="primerApellido"
          >
            <b-form-input
              id="primerApellido"
              v-model="alumno.primerApellido"
              placeholder="Ingresa el apellido paterno"
              :state="$v.alumno.primerApellido.$invalid
                && $v.alumno.primerApellido.$dirty
                ? false
                : null"
            />
            <b-form-invalid-feedback
              v-if="$v.alumno.primerApellido.$invalid
                && $v.alumno.primerApellido.$dirty"
              id="primerApellidoInfo"
            >
              El campo apellido paterno
              <span v-if="!$v.alumno.primerApellido.required">
                es requerido.
              </span>
              <span v-if="!$v.alumno.primerApellido.maxLength">
                debe tener máximo 250 caracteres.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: SegundoApellido -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Apellido Materno *"
            label-for="segundoApellido"
          >
            <b-form-input
              id="segundoApellido"
              v-model="alumno.segundoApellido"
              placeholder="Ingresa el apellido materno"
              :state="$v.alumno.segundoApellido.$invalid
                && $v.alumno.segundoApellido.$dirty
                ? false
                : null"
            />
            <b-form-invalid-feedback
              v-if="$v.alumno.segundoApellido.$invalid
                && $v.alumno.segundoApellido.$dirty"
              id="segundoApellidoInfo"
            >
              El campo apellido materno
              <span v-if="!$v.alumno.segundoApellido.required">
                es requerido.
              </span>
              <span v-if="!$v.alumno.segundoApellido.maxLength">
                debe tener máximo 250 caracteres.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Correo -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Correo"
            label-for="correo"
          >
            <b-form-input
              id="correo"
              v-model="alumno.correo"
              placeholder="Ingresa el correo"
              :state="alumno.correo
                ? $v.alumno.correo.$invalid == true
                  ? false
                  : null
                : null"
            />
            <b-form-invalid-feedback
              v-if="$v.alumno.$dirty"
              id="correoInfo"
            >
              El campo correo
              <span v-if="!$v.alumno.correo.email">
                debe ser un correo válido.
              </span>
              <span v-if="!$v.alumno.correo.maxLength">
                debe tener máximo 80 caracteres.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

      </b-row>

      <colLinea class="mt-2" />

      <!-- Header: INFORMACIÓN PEDAGÓGICA -->
      <div class="d-flex mb-1 mt-2">
        <feather-icon
          icon="BookOpenIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Información Pedagógica
        </h4>
      </div>

      <b-row>

        <!-- Field: Establecimientos -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Establecimiento *"
            label-for="establecimiento"
            :state="$v.alumno.idEstablecimiento.$invalid
              && $v.alumno.idEstablecimiento.$dirty
              ? false
              : null"
          >
            <v-select
              v-model="alumno.idEstablecimiento"
              placeholder="Selecciona el establecimiento..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="establecimientosOptions"
              label="nombre"
              :reduce="option => option.id"
              :disabled="!isAdmin"
              @input="setCursos"
            >
              <template #option="{ nombre, insignia }">
                <b-avatar
                  size="32"
                  :src="insignia"
                  :text="avatarText(nombre)"
                />
                <span> {{ nombre }}</span>
              </template>
            </v-select>
          </b-form-group>
          <b-form-invalid-feedback
            v-if="$v.alumno.idEstablecimiento.$invalid
              && $v.alumno.idEstablecimiento.$dirty"
            id="idEstablecimientoInfo"
          >
            El campo establecimiento
            <span v-if="!$v.alumno.idEstablecimiento.required">
              es requerido.
            </span>
          </b-form-invalid-feedback>
        </b-col>

        <!-- Field: Curso -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Curso *"
            label-for="curso"
            :state="$v.alumno.idCurso.$invalid && $v.alumno.idCurso.$dirty
              ? false
              : null"
          >
            <v-select
              v-model="alumno.idCurso"
              placeholder="Selecciona el curso"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="nombre"
              :options="cursosOptions"
              :reduce="option => option.id"
              input-id="idCurso"
              :disabled="!alumno.idEstablecimiento"
            />
          </b-form-group>
          <b-form-invalid-feedback
            v-if="$v.alumno.idCurso.$invalid && $v.alumno.idCurso.$dirty"
            id="idCursoInfo"
          >
            El campo curso
            <span v-if="!$v.alumno.idCurso.required">
              es requerido.
            </span>
          </b-form-invalid-feedback>
        </b-col>

        <!-- Field: NumMatricula -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Número Matrícula"
            label-for="numMatricula"
          >
            <b-form-input
              id="numMatricula"
              v-model="alumno.numMatricula"
              placeholder="Ingresa el número de matricula"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Prioritario -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Prioritario"
            label-for="prioritario"
          >
            <v-select
              v-model="alumno.idPrioritario"
              placeholder="Selecciona una opción"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="prioritariosOptions"
              label="nombre"
              :reduce="option => option.id"
            />
            <!-- :disabled="!alumno.pie" -->
          </b-form-group>
        </b-col>

        <!-- Field: PACI -->
        <b-col
          cols="12"
          md="4"
          lg="2"
        >
          <b-form-group
            label=" "
            label-for="paci"
            label-class="mb-2"
          >
            <b-form-checkbox
              v-model="alumno.paci"
            >
              PACI
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- Field: PIE -->
        <b-col
          cols="12"
          md="4"
          lg="2"
          class="mb-2"
        >
          <b-form-group
            label=" "
            label-for="pie"
            label-class="mb-2"
          >
            <b-form-checkbox
              v-model="alumno.pie"
              @input="changePie"
            >
              PIE
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- Field: Diagnóstico Pie -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Diagnóstico Pie"
            label-for="diagnosticoPie"
          >
            <v-select
              v-model="alumno.idDiagnostico"
              placeholder="Selecciona un diagnóstico"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="diagnosticosOptions"
              label="nombre"
              :reduce="option => option.id"
              :disabled="!alumno.pie"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <colLinea class="mt-2" />

      <!-- Action Buttons -->
        <!--v-if="cargando"-->
      <btnSubmit
        variant="primary"
        :btnText="btnSubmit"
        @processBtn="submitOption"
      />

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
  BOverlay, BCard, BFormRadioGroup, BFormCheckbox, BAvatar, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import {
  required, email, maxLength,
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

import { rut } from '@core/mixins/ui/rut'

import btnSubmit from '../../components/Form/btnSubmit.vue'
import colLinea from '../../components/Form/colLinea.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormCheckbox,
    BOverlay,
    BCard,
    BAvatar,
    vSelect,
    BFormDatepicker,

    btnSubmit,
    colLinea,
  },
  mixins: [validationMixin, rut],
  props: {
    alumno: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Alumno',
    },
  },
  data() {
    return {
      cargando: true,
      required,
      email,
      establecimientosOptions: [],
      cursosOptions: [],
      diagnosticosOptions: [],
      prioritariosOptions: [],
      tipoDocumentoOptions: [
        { id: 'RUT', nombre: 'RUT' },
        { id: 'IPE', nombre: 'IPE' },
      ],
      generoOptions: [
        { value: 'Femenino', text: 'Femenino' },
        { value: 'Masculino', text: 'Masculino' },
      ],
    }
  },
  validations: {
    alumno: {
      idEstablecimiento: {
        required,
      },
      idCurso: {
        required,
      },
      tipoDocumento: {
        required,
      },
      rut: {
        required,
        maxLength: maxLength(15),
      },
      nombres: {
        required,
        maxLength: maxLength(250),
      },
      primerApellido: {
        required,
        maxLength: maxLength(250),
      },
      segundoApellido: {
        required,
        maxLength: maxLength(250),
      },
      fechaNacimiento: {
        required,
      },
      correo: {
        email,
        maxLength: maxLength(80),
      },
      genero: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getEstablecimientosActivos: 'establecimientos/getEstablecimientosActivos',
      getCursosActivos: 'cursos/getCursosActivos',
      getDiagnosticos: 'diagnosticosPie/getDiagnosticos',
      getPrioritarios: 'prioritarios/getPrioritarios',
    }),
    isAdmin() {
      return this.getUser.rolActivo === 'Super Administrador'
            || this.getUser.rolActivo === 'Administrador Daem'
    },
  },
  mounted() {
    this.fetchEstablecimientosActivos().then(() => {
      this.fetchAttempt().then(() => {
        if (this.isAdmin) {
          this.setEstablecimientosAdmins(this.getEstablecimientosActivos)
        } else {
          this.setEstablecimientos(this.getEstablecimientosActivos[0])
        }
      })
    })
    this.fetchDiagnosticos().then(() => {
      this.setDiagnosticosPie(this.getDiagnosticos)
    })
    this.fetchPrioritarios().then(() => {
      this.setPrioritarios(this.getPrioritarios)
    })

    if (parseInt(this.alumno.idEstablecimiento, 10) > 0) {
      this.alumno.paci = this.alumno.paci === 1
      this.alumno.pie = this.alumno.pie === 1
    }
    this.setRut()
  },
  methods: {
    ...mapActions({
      fetchAttempt: 'auth/attempt',
      fetchDiagnosticos: 'diagnosticosPie/fetchDiagnosticos',
      fetchPrioritarios: 'prioritarios/fetchPrioritarios',
      fetchEstablecimientosActivos: 'establecimientos/fetchEstablecimientosActivos',
      fetchCursosActivos: 'cursos/fetchCursosActivos',
    }),
    setRut() {
      if (this.alumno.rut !== '') {
        this.alumno.rut = this.formatRut(this.alumno.rut)
      }
    },
    changePie() {
      if (!this.alumno.pie) {
        this.alumno.idDiagnostico = null
      }
    },
    submitOption() {
      this.$v.alumno.$touch()
      if (!this.$v.alumno.$invalid) {
        this.alumno.rut = this.alumno.rut.replace('-', '')
          .replace('.', '').replace('.', '')
        this.$emit('processForm', this.alumno)
      }
    },
    setDiagnosticosPie(diagnosticos) {
      this.diagnosticosOptions = []
      const { idDiagnostico } = this.alumno
      if (idDiagnostico !== null) {
        this.alumno.idDiagnostico = parseInt(idDiagnostico, 10)
      }
      diagnosticos.forEach(diagnostico => {
        const nombre = `${diagnostico.tipoNee} - ${diagnostico.nombre}`
        this.diagnosticosOptions.push({
          id: diagnostico.id,
          nombre,
        })
      })
    },
    setPrioritarios(prioritarios) {
      this.prioritariosOptions = []
      const { idPrioritario } = this.alumno
      if (idPrioritario !== null) {
        this.alumno.idPrioritario = parseInt(idPrioritario, 10)
      }
      prioritarios.forEach(prioritario => {
        this.prioritariosOptions.push({
          id: prioritario.id,
          nombre: prioritario.nombre,
        })
      })
    },
    setEstablecimientosAdmins(establecimientos) {
      this.establecimientosOptions = []
      establecimientos.forEach(establecimiento => {
        this.establecimientosOptions.push({
          id: establecimiento.id,
          nombre: establecimiento.nombre,
          insignia: establecimiento.insignia,
        })
      })
    },
    setEstablecimientos(establecimiento) {
      this.establecimientosOptions = []
      this.establecimientosOptions.push({
        id: establecimiento.id,
        nombre: establecimiento.nombre,
        insignia: establecimiento.insignia,
      })
      this.alumno.idEstablecimiento = establecimiento.id
      this.setCursos()
    },
    setCursos() {
      this.cursosOptions = []
      const { idCurso } = this.alumno
      this.alumno.idCurso = parseInt(idCurso, 10)
      this.fetchCursosActivos().then(() => {
        const cursos = this.getCursosActivos.filter(
          gca => gca.idEstablecimiento === this.alumno.idEstablecimiento,
        )
        cursos.forEach(curso => {
          const nombre = `${curso.nombreGrado} ${curso.letra}`
          this.cursosOptions.push({
            id: curso.id,
            nombre,
          })
        })
      })
    },
    // Vuexy
    onFileSelected(e) {
      // Obtiene primera Imagen
      const file = e.target.files[0]
      this.cargarImg(file)
    },
    cargarImg(file) {
      // Objeto que permite trabajar con archivos
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.alumno.insignia = e.target.result
      }
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.alumno.insignia = base64
    })

    return {
      avatarText,
      previewEl,
      refInputEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
  .mt-1-alarma {
    margin-top: 9px;
  }
</style>
